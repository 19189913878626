//该模块处理组件的事件（注意结构为：模块_selectOnChange）
export default class EventCompInf {
    //以下只拿了complexCard这个模块来演示他的select、comboGrid组件的改变事件；处理渲染列表列，明细表格结束编辑事件，如果还有其它模块，直接复制一份吧
    //注意:以下所有的事件，如果不需要，可以不用定义
    //---------------------------------------------表格列处理
    static complexCard_dealColumns(options:any):void{
        // options[0].label='abc';//那么外界模块的列名就变成了abc了
    }
    //---------------------------------------------comboGridChange
    //下拉comboGrid值改变事件接口（查询条件、表单、表单明细中的组件都触发这里的事件）
    static complexCard_comboGridChange(newValue:string, oldValue:string,comboId:string):void{
        // console.log(newValue,oldValue,comboId);
    }
    //---------------------------------------------selectOnchange
    //下拉select值改变事件接口（查询条件、表单、表单明细中的组件都触发这里的事件）
    static complexCard_selectOnChange(newValue:string,selectId:string,inst:any):void{
        // console.log(newValue,selectId,inst);
    }
    //---------------------------------------------beforeEndEdit
    //明细表格结束编辑事件接口（如果还有其它事件，请在EventHelper.dealEvent的_beforeOpen项，也就是打开弹出框的时候，把事件写进去，会自动绑定到卡片的明细表格里面）
    static async complexCard_beforeEndEdit(options:any):Promise<boolean>{
        // console.log(options.parentInst.engineParams.compParams.modelPath);//模块路径，eg：/complexCard
        switch (options.detailType) {
            case "cardDetail":
                options.row.F_D_FIELD2Name =  options.parentInst.refMap.get('F_D_FIELD2').comboText;//设置下拉树选取的文本值到行中
                options.row.F_D_FIELD5Name =  options.parentInst.refMap.get('F_D_FIELD5').states.selected.currentLabel;//设置下拉select选取的文本值到行中
                options.row.F_D_FIELD4Name =  options.parentInst.refMap.get('F_D_FIELD4').comboText;//设置下拉表格选取的文本值到行中
                options.parentInst.refMap.get(options.detailType).setRowData(options.row, options.ndex);
                break;
        }
        return true;
    }
}