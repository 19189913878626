import Axios from "@/utils/axios";
import Tools from "@/utils/Tools";
import config from "@/utils/config";
import ApiHelper from "@/utils/api/ApiHelper";
export default class Api extends ApiHelper{
    static buildUrl(url:string):string{
        if(config.isCloud)url=config.modelTypes.system+url;
        return url;
    }
    //----------------------------genCode began-----------------------------
    //构建树形系统数据库表
    static async tableTreeData(options?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/genCode/custom"), params: Object.assign({operateType:'tree'},options.params) });
    }
    //生成代码
    static async genCode(options?:any):Promise<any>{
        return await Axios.$$post({ url:this.buildUrl("/genCode/custom"), params: Object.assign({operateType:'genCode'},options.params) });
    }
    //----------------------------genCode end-----------------------------
    //共用发送请求
    static async postRequest(options?:any):Promise<any>{
        return await Axios.$$post({ url: options.url, params: options.params });
    }

    //机构数据
    static async corpTreeData(params?:any):Promise<any>{//默认要按数据权限来构建树，如果想要显示所有树节点，需要指定showType:2
        let res = await Axios.$$post({ url: this.buildUrl("/corp/custom"), params: Object.assign({operateType:'tree'},params) });
        return res.data;
    }
    //部门数据
    static async deptTreeData(params?:any):Promise<any>{
        let res = await Axios.$$post({ url: this.buildUrl("/dept/custom"), params: Object.assign({operateType:'tree'},params)});
        return res.data;
    }
    //人员数据
    static async getPersons(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/person/custom"), params: Object.assign({operateType:'getPersons'},params)});
        return this.buildSelectData(result);
    }
    //注册
    static async register(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/user/register"), params: params});
    }
    //登录初始化数据
    static async loginInitData(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/user/loginInit"), params: params});
    }
    //用户登录
    static async login(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/user/login"), params: params });
    }
    //退出登录
    static async loginOut(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/user/custom"), params: Object.assign({operateType:'loginOut'},params) });
    }
    //加载个人信息请求
    static async personalInfos(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/user/custom"), params: Object.assign({operateType:'personalInfos'},params) });
    }
    //修改个人信息请求
    static async changeOtherInfo(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/user/custom"), params: Object.assign({operateType:'changeOtherInfo'},params) });
    }
    //修改密码
    static async changePassword(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/user/custom"), params: Object.assign({operateType:'changePassword'},params) });
    }
    //用户账号数据
    static async getUsers(params?:any):Promise<void>{
        let result=await Axios.$$post({ url: this.buildUrl("/user/custom"), params: Object.assign({operateType:'getUsers'},params)});
        return this.buildSelectData(result);
    }
    //保存layer用户布局
    static async saveLayerLayout(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/user/custom"), params: Object.assign({operateType:'layerLayout'},params)});
    }
    //在线用户账号数据
    static async getOnlineUsers(params?:any):Promise<void>{
        let result=await Axios.$$post({ url: this.buildUrl("/user/custom"), params: Object.assign({operateType:'onlineUser'},params)});
        return this.buildSelectData(result);
    }
    //解锁用户
    static async unlockUser(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/user/custom"), params: Object.assign({operateType:'unlock'},params) });
    }
    //加载所有的角色
    static async getRoles(params?:any):Promise<void>{
        let result=await Axios.$$post({ url: this.buildUrl("/role/custom"), params: Object.assign({operateType:'getRoles'},params)});
        return this.buildSelectData(result);
    }
    //查询用户拥有的角色和用户自己建立的角色
    static async getUserRoles(params?:any):Promise<void>{
        let result=await Axios.$$post({ url: this.buildUrl("/role/custom"), params: Object.assign({operateType:'getUserRoles'},params)});
        let data=[] as any;
        result.resultData.forEach((item:any,index:number)=>{
            data.push({value:item.id,label:item.name,item:item,disabled:item.flag});
        })
        return data;
    }
    //加载指定用户菜单
    static async loadUserMenus(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/roleMenu/custom"), params: Object.assign({operateType:'userMenus'},params) });
    }
    //加载指定角色菜单
    static async loadRoleMenu(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/roleMenu/load"), params: params });
    }
    //保存角色菜单
    static async saveRoleMenu(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/roleMenu/save"), params: params });
    }
    //加载用户portals
    static async loadUserPortals(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/userPortal/custom"), params: Object.assign({operateType:'portals'},params)});
    }
    //保存用户设置的portals桌面
    static async saveUserPortals(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/userPortal/custom"), params: Object.assign({operateType:'saveUserPortal'},params)});
    }

    static async downLoadResourceFile (url:string,sourceName:string,downLoadFileName:string):Promise<void>{
        await Axios.$$downLoad({url:url, params: {sourceName:sourceName,fileName:downLoadFileName}});
    }
    //---------------------------attach---------------------------began--------------------
    //下载附件---独立服务器处理
    static async downLoad (params:any):Promise<void>{
        //用这个会报错，比如单据附件下载
        // await Axios.$$downLoad({url:this.buildUrl('/attach/custom'), params:Object.assign({operateType:'downLoadFile'},params)});
        await Axios.$$downLoad({url:this.buildUrl('/attach/downLoadFile'),params:params});
    }
    //下载资源文件downloadFiles文件夹下的文件
    static async downLoadResourceFiles (params:any):Promise<void>{
        await Axios.$$downLoad({url:params.url, params:params});
    }
    //删除文件--独立服务器处理
    static async deleteFile (params?:any):Promise<any>{
        return await Axios.$$post({url: this.buildUrl("/attach/custom"),params: Object.assign({operateType:'deleteFile'},params)});
    }
    //删除指定资源图片--独立服务器处理
    static async deleteImageSource (params?:any):Promise<any>{
        return await Axios.$$post({url: this.buildUrl("/attach/custom"), params: Object.assign({operateType:'deleteImageSource'},params)});
    }
    //加载模块的文件列表，上传的时候没有指定小类-type，会默认是0，那么加载模块文件列表的时候也可以不用指定小类，这样就默认会按文件大类加载文件列表
    static async loadByBelongMaxIdAndType (params?:any):Promise<any>{
        let uploadSaveType=Tools.getPropFromVuex('uploadSaveType');
        return await Axios.$$post({url: this.buildUrl("/attach/custom"), params: Object.assign({operateType:'loadByBelongMaxIdAndType'},params)});
    }
    //云处理上传文件-保存云上传之后返回的信息到数据库
    static async saveUploadFiles (params?:any):Promise<any>{
        //如果自己指定了url，表示它想自己指定请求地址来处理上传完成之后的信息，如果没有指定url，那么就交给/attach/custom来处理
        let url='/attach/custom';
        if(params.url)url=params.url;
        //不管是交给系统默认的attach处理还是自己定义url来处理，都会交给custom来处理，operateType=saveUploadFiles是来区分操作的
        return await Axios.$$post({url: this.buildUrl(url),  params:Object.assign({operateType:'saveUploadFiles'},params)});
    }
    //自己处理上传文件-没有自己指定上传地址，那么就采用独立服务器处理
    static async uploadFile (params?:any):Promise<any>{
        return await Axios.$$upload({url: this.buildUrl("/attach/uploadFiles"), params:params});
    }
    //自己处理上传文件-指定了处理地址，则是集群机器来处理(比如上传excel并且解析)
    static async speUploadFile (params?:any):Promise<any>{
        let url=params.action;
        if(config.contextPath)url=config.contextPath +url;
        url=config.baseURL+url;
        return await Axios.$$upload({url: url, params:params});
    }
    //---------------------------attach--------------------------end---------------------
    //签收消息
    static async messageSign(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/message/custom"), params: Object.assign({operateType:'receive'},params) });
    }
    //消息接收详情
    static async messageReceiveDetail(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/message/custom"), params: Object.assign({operateType:'receiveDetail'},params) });
    }

    //强退在线用户
    static async onlineuserCustom(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/onlineUser/custom"), params: Object.assign({operateType:'forceOut'},params) });
    }
    //重载缓存
    static async reloadCache(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/reloadcache/custom"), params: Object.assign({operateType:'reload'},params) });
    }
    //立即执行定时任务
    static async executeTask(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/task/custom"), params: Object.assign({operateType:'executeTask'},params) });
    }
    //更改定时任务状态
    static async changeTaskStatus(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/task/custom"), params: Object.assign({operateType:'changeTaskStatus'},params) });
    }

    //哪些fields可以用作流程条件
    static async conditionFields (params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl(params.modelUrl+"/getFields"), params: params });
    }
    //单据类别树形数据
    static async billTypeTreeData (params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/waitWork/custom"), params: Object.assign({operateType:'billTypeTreeData'},params) });
    }
    //批量驳回
    static async batchRollBack (params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/waitWork/custom"), params: Object.assign({operateType:'batchRollBack'},params) });
    }
    //动态生成id
    static async getNewId (params?:any):Promise<any>{
        let res =await Axios.$$post({ url: this.buildUrl("/utils/custom"), params: Object.assign({operateType:'getNewId'},params) });
        return res.id;
    }
    //阿里oss存储临时key
    static async getAliOssKey (params?:any):Promise<any>{
        let res =await Axios.$$post({ url: this.buildUrl("/utils/custom"), params: Object.assign({operateType:'aliOssKey'},params) });
        return res.data;
    }
    //查询未确认的消息数量
    static async queryNotQsMsgNum(params?:any):Promise<any>{
        return await Axios.$$post({ url: this.buildUrl("/utils/custom"), params: Object.assign({operateType:'queryNotQsMsgNum'},params) });
    }
    //关于系统页面数据
    static async aboutInfo(params?:any):Promise<void>{
        return await Axios.$$post({ url: this.buildUrl("/utils/custom"), params: Object.assign({operateType:'aboutInfo'},params) });
    }
    //构造省份城市区域树
    static async loadProvinceCityAreaTreeData(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/area/custom"), params: Object.assign({operateType:'buildProvinceCityAreaTreeData'},params) });
        return JSON.parse(result.treeData);
    }
    //加载所有的省份
    static async loadAllProvince(params?:any):Promise<any>{
        let result=await Axios.$$post({ url: this.buildUrl("/area/custom"), params: Object.assign({operateType:'loadAllProvince'},params) });
        return this.buildSelectData(result)
    }
    //加载所有的城市
    static async loadAllCity(params?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/area/custom"), params: Object.assign({operateType:'loadAllCity'},params) });
        return this.buildSelectData(result)
    }
    //加载指定省份下所有的城市
    static async getCitiesByProvince(params?:any):Promise<any>{
        let result= await Axios.$$post({ url: this.buildUrl("/area/custom"), params: Object.assign({operateType:'getCitiesByProvince'},params) });
        return this.buildSelectData(result)
    }
}