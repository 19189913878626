import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-90d60d26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "number" }
const _hoisted_2 = { class: "unit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_input_number = _resolveComponent("el-input-number")!

  return (_openBlock(), _createElementBlock("div", {
    class: "numberUnit",
    ref: el=>_ctx.refMap.set('numberUnit',el)
  }, [
    _createElementVNode("span", _hoisted_1, [
      (_ctx.type=='input')
        ? (_openBlock(), _createBlock(_component_el_input, _mergeProps({
            key: 0,
            modelValue: _ctx.otherParams.inputAttrs.defaultValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.otherParams.inputAttrs.defaultValue) = $event))
          }, _ctx.$attrs, {
            onChange: _ctx.inputChange,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('inputHandler', _ctx.otherParams.inputAttrs.defaultValue))),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blurHandler'))),
            onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('focusHandler'))),
            onClear: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('clearHandler')))
          }), null, 16, ["modelValue", "onChange"]))
        : (_openBlock(), _createBlock(_component_el_input_number, _mergeProps({
            key: 1,
            modelValue: _ctx.otherParams.numberAttrs.defaultValue,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.otherParams.numberAttrs.defaultValue) = $event)),
            min: _ctx.otherParams.numberAttrs.min,
            max: _ctx.otherParams.numberAttrs.max,
            style: {"width":"100%"},
            controls: false
          }, _ctx.$attrs, {
            onChange: _ctx.numberInputChange,
            onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('inputHandler', _ctx.otherParams.numberAttrs.defaultValue))),
            onBlur: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('blurHandler'))),
            onFocus: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('focusHandler'))),
            onClear: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('clearHandler')))
          }), null, 16, ["modelValue", "min", "max", "onChange"]))
    ]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.otherParams.unit), 1)
  ], 512))
}