import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-aa939006"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_2 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_3 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_4 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_5 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_6 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_7 = {
  key: 0,
  style: {"margin-left":"10px"}
}
const _hoisted_8 = { style: {"margin-left":"10px"} }
const _hoisted_9 = {
  key: 0,
  style: {"margin-left":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_ComboGrid = _resolveComponent("ComboGrid")!
  const _component_ComboTree = _resolveComponent("ComboTree")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_rate = _resolveComponent("el-rate")!
  const _component_el_slider = _resolveComponent("el-slider")!
  const _component_el_input_number = _resolveComponent("el-input-number")!

  return _renderSlot(_ctx.$slots, "default", {}, () => [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.editTableItems, (item) => {
      return _renderSlot(_ctx.$slots, item.prop, {}, () => [
        (item.type=='text')
          ? (_openBlock(), _createBlock(_component_el_table_column, {
              key: 0,
              label: item.label,
              prop: item.prop
            }, null, 8, ["label", "prop"]))
          : (item.type=='input')
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 1,
                label: item.label,
                prop: item.prop
              }, {
                default: _withCtx((scope) => [
                  (!scope.row.EDIT)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(scope.row[item.prop]), 1))
                    : (_openBlock(), _createBlock(_component_el_input, _mergeProps({
                        key: 1,
                        placeholder: '请输入'+item.label,
                        modelValue: scope.row[item.prop],
                        "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event),
                        clearable: "",
                        ref_for: true
                      }, item.attrs), null, 16, ["placeholder", "modelValue", "onUpdate:modelValue"]))
                ]),
                _: 2
              }, 1032, ["label", "prop"]))
            : (item.type=='cascader')
              ? (_openBlock(), _createBlock(_component_el_table_column, {
                  key: 2,
                  label: item.label,
                  prop: item.prop
                }, {
                  default: _withCtx((scope) => [
                    (!scope.row.EDIT)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(scope.row[item.prop+'Name']), 1))
                      : (_openBlock(), _createBlock(_component_el_cascader, _mergeProps({
                          key: 1,
                          ref_for: true,
                          ref: el=>_ctx.refMap.set(item.prop,el),
                          props: {emitPath:false},
                          modelValue: scope.row[item.prop],
                          "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event),
                          options: item.data,
                          onChange: (val)=>{if(item.event)item.event(val,item.prop,_ctx.ownerInst)}
                        }, item.attrs), null, 16, ["modelValue", "onUpdate:modelValue", "options", "onChange"]))
                  ]),
                  _: 2
                }, 1032, ["label", "prop"]))
              : (item.type=='select')
                ? (_openBlock(), _createBlock(_component_el_table_column, {
                    key: 3,
                    label: item.label,
                    prop: item.prop
                  }, {
                    default: _withCtx((scope) => [
                      (!scope.row.EDIT)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.row[item.prop+'Name']), 1))
                        : (_openBlock(), _createBlock(_component_el_select, _mergeProps({
                            key: 1,
                            ref_for: true,
                            ref: el=>_ctx.refMap.set(item.prop,el),
                            modelValue: scope.row[item.prop],
                            "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event),
                            clearable: "",
                            placeholder: "请选择下拉select",
                            style: {"width":"100%"},
                            onChange: (val)=>{if(item.event)item.event(val,item.prop,_ctx.ownerInst)}
                          }, item.attrs), {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.value,
                                  label: item.label,
                                  value: item.value,
                                  disabled: item.disabled
                                }, null, 8, ["label", "value", "disabled"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1040, ["modelValue", "onUpdate:modelValue", "onChange"]))
                    ]),
                    _: 2
                  }, 1032, ["label", "prop"]))
                : (item.type=='select-v2')
                  ? (_openBlock(), _createBlock(_component_el_table_column, {
                      key: 4,
                      label: item.label,
                      prop: item.prop
                    }, {
                      default: _withCtx((scope) => [
                        (!scope.row.EDIT)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(scope.row[item.prop+'Name']), 1))
                          : (_openBlock(), _createBlock(_component_el_select, _mergeProps({
                              key: 1,
                              ref_for: true,
                              ref: el=>_ctx.refMap.set(item.prop,el),
                              modelValue: scope.row[item.prop],
                              "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event),
                              clearable: "",
                              placeholder: "请选择下拉select",
                              onChange: (val)=>{if(item.event)item.event(val,item.prop,_ctx.ownerInst)},
                              options: item.data
                            }, item.attrs), null, 16, ["modelValue", "onUpdate:modelValue", "onChange", "options"]))
                      ]),
                      _: 2
                    }, 1032, ["label", "prop"]))
                  : (item.type=='comboGrid')
                    ? (_openBlock(), _createBlock(_component_el_table_column, {
                        key: 5,
                        label: item.label,
                        prop: item.prop
                      }, {
                        default: _withCtx((scope) => [
                          (!scope.row.EDIT)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(scope.row[item.prop+'Name']), 1))
                            : (_openBlock(), _createBlock(_component_ComboGrid, _mergeProps({
                                key: 1,
                                ref_for: true,
                                ref: el=>_ctx.refMap.set(item.prop,el),
                                modelValue: scope.row[item.prop],
                                "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event),
                                params: {comboId:item.prop,modelMethod:item.modelMethod}
                              }, item.attrs), null, 16, ["modelValue", "onUpdate:modelValue", "params"]))
                        ]),
                        _: 2
                      }, 1032, ["label", "prop"]))
                    : (item.type=='comboTree')
                      ? (_openBlock(), _createBlock(_component_el_table_column, {
                          key: 6,
                          label: item.label,
                          prop: item.prop
                        }, {
                          default: _withCtx((scope) => [
                            (!scope.row.EDIT)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(scope.row[item.prop+'Name']), 1))
                              : (_openBlock(), _createBlock(_component_ComboTree, _mergeProps({
                                  key: 1,
                                  ref_for: true,
                                  ref: el=>_ctx.refMap.set(item.prop,el),
                                  modelValue: scope.row[item.prop],
                                  "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event),
                                  params: {comboId:item.prop,modelMethod:item.modelMethod}
                                }, item.attrs), null, 16, ["modelValue", "onUpdate:modelValue", "params"]))
                          ]),
                          _: 2
                        }, 1032, ["label", "prop"]))
                      : (item.type=='datePicker')
                        ? (_openBlock(), _createBlock(_component_el_table_column, _mergeProps({
                            key: 7,
                            label: item.label,
                            prop: item.prop,
                            ref_for: true
                          }, item.attrs), {
                            default: _withCtx((scope) => [
                              (!scope.row.EDIT)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(scope.row[item.prop]), 1))
                                : (_openBlock(), _createBlock(_component_el_date_picker, {
                                    key: 1,
                                    modelValue: scope.row[item.prop],
                                    "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event),
                                    placeholder: '请选择'+item.label
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]))
                            ]),
                            _: 2
                          }, 1040, ["label", "prop"]))
                        : (item.type=='switch')
                          ? (_openBlock(), _createBlock(_component_el_table_column, _mergeProps({
                              key: 8,
                              label: item.label,
                              prop: item.prop,
                              ref_for: true
                            }, item.attrs), {
                              default: _withCtx((scope) => [
                                _createVNode(_component_el_switch, {
                                  modelValue: scope.row[item.prop],
                                  "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event),
                                  "active-value": "true"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1040, ["label", "prop"]))
                          : (item.type=='rate')
                            ? (_openBlock(), _createBlock(_component_el_table_column, _mergeProps({
                                key: 9,
                                label: item.label,
                                prop: item.prop,
                                ref_for: true
                              }, item.attrs), {
                                default: _withCtx((scope) => [
                                  _createVNode(_component_el_rate, {
                                    modelValue: scope.row[item.prop],
                                    "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event),
                                    "allow-half": ""
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                              }, 1040, ["label", "prop"]))
                            : (item.type=='slider')
                              ? (_openBlock(), _createBlock(_component_el_table_column, _mergeProps({
                                  key: 10,
                                  label: item.label,
                                  prop: item.prop,
                                  ref_for: true
                                }, item.attrs), {
                                  default: _withCtx((scope) => [
                                    _createElementVNode("div", _hoisted_8, [
                                      _createVNode(_component_el_slider, {
                                        modelValue: scope.row[item.prop],
                                        "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event)
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                    ])
                                  ]),
                                  _: 2
                                }, 1040, ["label", "prop"]))
                              : (item.type=='inputNumber')
                                ? (_openBlock(), _createBlock(_component_el_table_column, _mergeProps({
                                    key: 11,
                                    label: item.label,
                                    prop: item.prop,
                                    ref_for: true
                                  }, item.attrs), {
                                    default: _withCtx((scope) => [
                                      (!scope.row.EDIT)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(scope.row[item.prop]), 1))
                                        : (_openBlock(), _createBlock(_component_el_input_number, {
                                            key: 1,
                                            modelValue: scope.row[item.prop],
                                            "onUpdate:modelValue": ($event: any) => ((scope.row[item.prop]) = $event),
                                            min: 0,
                                            max: 99999999999,
                                            style: {"width":"100%"}
                                          }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                    ]),
                                    _: 2
                                  }, 1040, ["label", "prop"]))
                                : _createCommentVNode("", true)
      ], true)
    }), 256))
  ], true)
}