import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardFormItem = _resolveComponent("CardFormItem")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      (_ctx.colNum==1)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.formItems, (item) => {
            return (_openBlock(), _createBlock(_component_el_form_item, {
              label: item.label,
              prop: item.javaName
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, item.javaName, {}, () => [
                  _createVNode(_component_CardFormItem, {
                    item: item,
                    form: _ctx.form,
                    formRef: _ctx.formRef,
                    disabled: _ctx.disabled,
                    refMap: _ctx.refMap,
                    ownerInst: _ctx.ownerInst
                  }, null, 8, ["item", "form", "formRef", "disabled", "refMap", "ownerInst"])
                ], true)
              ]),
              _: 2
            }, 1032, ["label", "prop"]))
          }), 256))
        : _createCommentVNode("", true),
      (_ctx.colNum==2)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.otherParams.rowNum, (index) => {
            return (_openBlock(), _createBlock(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 11 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.formItems[(index-1)*2].label,
                      prop: _ctx.formItems[(index-1)*2].javaName
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, _ctx.formItems[(index-1)*2].javaName, {}, () => [
                          _createVNode(_component_CardFormItem, {
                            item: _ctx.formItems[(index-1)*2],
                            form: _ctx.form,
                            formRef: _ctx.formRef,
                            disabled: _ctx.disabled,
                            refMap: _ctx.refMap,
                            ownerInst: _ctx.ownerInst
                          }, null, 8, ["item", "form", "formRef", "disabled", "refMap", "ownerInst"])
                        ], true)
                      ]),
                      _: 2
                    }, 1032, ["label", "prop"])
                  ]),
                  _: 2
                }, 1024),
                (((index-1)*2+1)<_ctx.formItems.length)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 0,
                      span: 11,
                      offset: 2
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: _ctx.formItems[(index-1)*2+1].label,
                          prop: _ctx.formItems[(index-1)*2+1].javaName
                        }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, _ctx.formItems[(index-1)*2+1].javaName, {}, () => [
                              _createVNode(_component_CardFormItem, {
                                item: _ctx.formItems[(index-1)*2+1],
                                form: _ctx.form,
                                formRef: _ctx.formRef,
                                disabled: _ctx.disabled,
                                refMap: _ctx.refMap,
                                ownerInst: _ctx.ownerInst
                              }, null, 8, ["item", "form", "formRef", "disabled", "refMap", "ownerInst"])
                            ], true)
                          ]),
                          _: 2
                        }, 1032, ["label", "prop"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024))
          }), 256))
        : _createCommentVNode("", true),
      (_ctx.colNum==3)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.otherParams.rowNum, (index) => {
            return (_openBlock(), _createBlock(_component_el_row, null, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (item) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (((index-1)*3+item-1)<_ctx.formItems.length)
                      ? (_openBlock(), _createBlock(_component_el_col, {
                          key: 0,
                          span: 8
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: _ctx.formItems[(index-1)*3+item-1].label,
                              prop: _ctx.formItems[(index-1)*3+item-1].javaName
                            }, {
                              default: _withCtx(() => [
                                _renderSlot(_ctx.$slots, _ctx.formItems[(index-1)*3+item-1].javaName, {}, () => [
                                  _createVNode(_component_CardFormItem, {
                                    item: _ctx.formItems[(index-1)*3+item-1],
                                    form: _ctx.form,
                                    formRef: _ctx.formRef,
                                    disabled: _ctx.disabled,
                                    refMap: _ctx.refMap,
                                    ownerInst: _ctx.ownerInst
                                  }, null, 8, ["item", "form", "formRef", "disabled", "refMap", "ownerInst"])
                                ], true)
                              ]),
                              _: 2
                            }, 1032, ["label", "prop"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 64))
              ]),
              _: 2
            }, 1024))
          }), 256))
        : _createCommentVNode("", true),
      (_ctx.colNum==4)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.otherParams.rowNum, (index) => {
            return (_openBlock(), _createBlock(_component_el_row, null, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (item) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (((index-1)*4+item-1)<_ctx.formItems.length)
                      ? (_openBlock(), _createBlock(_component_el_col, {
                          key: 0,
                          span: 6
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: _ctx.formItems[(index-1)*4+item-1].label,
                              prop: _ctx.formItems[(index-1)*4+item-1].javaName
                            }, {
                              default: _withCtx(() => [
                                _renderSlot(_ctx.$slots, _ctx.formItems[(index-1)*4+item-1].javaName, {}, () => [
                                  _createVNode(_component_CardFormItem, {
                                    item: _ctx.formItems[(index-1)*4+item-1],
                                    form: _ctx.form,
                                    formRef: _ctx.formRef,
                                    disabled: _ctx.disabled,
                                    refMap: _ctx.refMap,
                                    ownerInst: _ctx.ownerInst
                                  }, null, 8, ["item", "form", "formRef", "disabled", "refMap", "ownerInst"])
                                ], true)
                              ]),
                              _: 2
                            }, 1032, ["label", "prop"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 64))
              ]),
              _: 2
            }, 1024))
          }), 256))
        : _createCommentVNode("", true)
    ], true),
    _renderSlot(_ctx.$slots, "extendSlot", {}, undefined, true)
  ], 64))
}