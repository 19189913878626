import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import Tools from "@/utils/Tools";
import Const from "@/utils/Const";
import UtilPub from "@/utils/UtilPub";
import config from './config'
//(可以删掉了)
class AxiosUtil{
    //不加密的url（前端请求的时候，参数不会加密传输到后台，而且会在header中设置不需要加密的标识，
    //所以后台收到请求之后，会读取header中的这个标识来决定是否需要解密，而且后端处理完之后，
    //往前端返回结果是否需要加密，判断的依据也是从header中取出这个标识看是否需要把结果进行加密返回前端，
    //当然那个标识其实是在拦截器中添加进去的，可拦截器添加的时候也是看前端传过来的这个标识是否需要加密，所以就相当于是这里的这个标识
    public static notEncryptUri:Array<string>=[
        '/user/loginInit',
        '/downLoadResourceFile',
        '/downLoadFile'
    ]
    public static isNotEncryptData(uri:string):boolean{
        // let index=AxiosUtil.notEncryptUri.findIndex((item:string)=>{
        //     return uri.indexOf(item)>-1;
        // });
        // return index>-1;
        return false;
    }
}
//创建axios实例
const axiosInst:AxiosInstance = axios.create({
    timeout: 1*60*60*1000//一小时超时
});
//不能把baseURL放到axios实例身上，因为发请求的时候，会根据业务需求去构建url
// const baseURL='http://127.0.0.1:7788';
//前端请求拦截器（发送请求之前）。
//往即将发送请求的header中放入一些参数，包括encryptData、userToken,后台收到请求之后，就可以从header中取出这些参数。
//注意：虽然参数采用驼峰放入header中，但是从header中取的时候必须全部小写，所以放入的时候是不区分大小写的
axiosInst.interceptors.request.use((reqConfig:AxiosRequestConfig): AxiosRequestConfig => {
        //01）往header中放入encryptData
        if(AxiosUtil.isNotEncryptData(reqConfig.url as string)){//不需要加密的uri请求
            reqConfig.headers?reqConfig.headers["encryptData"] = false:'';
        }else{//其它uri请求是否需要加密看后台的设置情况
            //数据是否加密传输，每次请求都带上放到header中，方便在controller接收和返回处理中决定是否解密和加密
            reqConfig.headers?reqConfig.headers["encryptData"] = sessionStorage.getItem('encryptData') as string:'';
        }
        //02）往header中放入Jf-Access-Token
        //每次发请求都往header中放入token，后台会取出token进行验证。包括是否传入token，token是否合法、是否过期等。
        const userToken = sessionStorage.getItem(Const.jfAccessToken);
        //要判断sessionStorage中是否有token，有可能还未登录，只是进入登录页面发送的后台请求，那么就还没有token,
        //只有登录成功之后，后台才会把token返回给前台，然后前台才把token放入sessionStorage中
        if(userToken)reqConfig.headers?reqConfig.headers[Const.jfAccessToken] = userToken:'';
        //系统模块集合字符串，如果不是微服务模式，可以不用往header中放applications，也就是可以删掉下方代码
        const applications = config.applications.toString();
        // @ts-ignore
        reqConfig.headers['applications'] = applications;
        // @ts-ignore
        reqConfig.headers['currentLang'] = localStorage.getItem('currentLang')?localStorage.getItem('currentLang'):'zh-CN';
        return reqConfig;//必须返回reqConfig，否则会报错：Cannot read property 'cancelToken' of undefined
    },
    (error) => {
        return Promise.reject(error);
    }
);
//响应拦截器（请求响应之后）
//后端往前端返回的内容中，会把一些参数写入header中，比如本次请求的状态码code、Jf-Access-Token（token可能过期更新了）
//注意：从header中取参数的时候必须全部小写。响应懒截器执行完之后，才会执行请求结果promise
axiosInst.interceptors.response.use((res:AxiosResponse) => {
        const invalidCode=[Const._0001,Const._0002,Const._0009,Const._0010];
        //如果本次请求状态为成功，且响应结果header中包含有Jf-Access-Token，那么把header中的Jf-Access-Token取出更新sessionStorage中的f-Access-Token
        if(res.headers[Const.code.toLowerCase()]==Const._0000 && res.headers[Const.jfAccessToken.toLowerCase()]){
            sessionStorage.setItem(Const.jfAccessToken, res.headers[Const.jfAccessToken.toLowerCase()]);//更新本地Jf-Access-Token
            window.localStorage.setItem(Const.jmReportAccessToken, res.headers[Const.jfAccessToken.toLowerCase()]);//更新本地积木的token，可删除
        }else if(invalidCode.includes(res.headers[Const.code.toLowerCase()])){//token验证失败token(0001)没有传入(0002)token过期(0009)非法访问（0010），跳转到登录页
            //对于非法访问，提示一下吧
            if(res.headers[Const.code.toLowerCase()]==Const._0010){
                alert('亲，你在恶意访问哦，惩罚你不能使用不系统一分钟以上')
            }
            sessionStorage.removeItem(Const.jfAccessToken);
            sessionStorage.removeItem("userInfo");
            location.reload();//由于userInfo被去除了。所以reload整个页面会在路由拦截那里经过判断，然后跳转到登录页
        }
        //响应成功，继续交给后续处理。必须返回，否则我们的请求不能得到响应结果
        return Promise.resolve(res);
    },
    (err) => {
        console.log(err);
        return Promise.reject(err);
    }
);

export default class Axios {
    //发送post请求
    static $$post(options:any):Promise<any>{
        return new Promise((resolve, reject) => {
            let params =options.params;
            if(params && sessionStorage.getItem('encryptData')=='true' && !AxiosUtil.isNotEncryptData(options.url)){
                params = UtilPub.encrypt(JSON.stringify(params));//如果要加密传输，需要把参数进行加密处理
            }
            axiosInst({
                method: "post",
                url: Axios.buildFullUrl(options.url),
                headers:{'Content-Type':'application/json'},
                data:params
            }).then((res:any) => {
                if(res.data && res.headers[Const.code.toLowerCase()]==Const._0000){//本次请求成功且有数据返回
                    //如果进行过加密处理，那么返回回来之后需要进行对结果解密
                    if(sessionStorage.getItem('encryptData')=='true')res.data=JSON.parse(UtilPub.decrypt(res.data));
                    if (!res.data.result && res.data.msg)Tools.error({ message: res.data.msg });
                    resolve(res.data);
                }
            }).catch((err:any) => {
                Tools.error();
                reject(err)
            });
        });
    }
    //自己处理文件上传-访问独立服务器（只有自己上传文件才会访问该方法，云上传访问的是$$post来保存云返回的信息）
    static $$upload(options:any):Promise<any>{
        return new Promise((resolve, reject) => {
            // axiosInst.post(Axios.buildFullUrl(options.url),options.params.formData)
            axiosInst.post(options.url,options.params.formData)
                     .then((res:any)=> {//formData包含了上传的文件信息
                         if(res.data && sessionStorage.getItem('encryptData')=='true' && !AxiosUtil.isNotEncryptData(options.url)){
                             res.data=JSON.parse(UtilPub.decrypt(res.data));
                         }
                         resolve(res.data);
                     }).catch((err:any) => {
                         Tools.error();
                         reject(err);
                     });
        });
    }
    //文件下载。
    static $$downLoad(options:any):Promise<any>{
        return new Promise((resolve, reject) => {
            let params =options.params;
            if(params && sessionStorage.getItem('encryptData')=='true' && !AxiosUtil.isNotEncryptData(options.url)){
                params = UtilPub.encrypt(JSON.stringify(params));//如果要加密传输，需要把参数进行加密处理
            }
            axiosInst({
                url: Axios.buildFullUrl(options.url),
                method: 'post',
                responseType: 'blob',     //接收类型设置，否者返回字符型
                headers:{'Content-Type':'application/json'},
                data: params
            }).then((res:any) => {//注意：下载附件后台返回的状态码key叫做downloadcode
                if(Const._0000==res.headers[Const.downloadcode.toLowerCase()]){
                    const blob = res.data
                    const reader = new FileReader()
                    reader.readAsDataURL(blob)
                    reader.onload = (e: ProgressEvent<FileReader>) => {
                        const a = document.createElement('a')||{};
                        a.download = options.params.fileName;
                        // @ts-ignore
                        a.href=e.target.result;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                }else{
                    Tools.error({message:Tools.ctx.$t('downloadFileFail')});
                }
                resolve(res);
            }).catch((err:any) => {
                Tools.error();
            });
        });
    }
    //构建完整的url
    static buildFullUrl(url:string):string{
        if(config.contextPath)url=config.contextPath +url;
        return config.baseURL+url;
    }
}