import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "box",
    ref: el=>_ctx.refMap.set('box',el)
  }, [
    _createElementVNode("div", {
      class: "left",
      style: _normalizeStyle({width: 'calc('+(_ctx.otherParams.lwidth)+' - 5px)',minWidth:'54px'}),
      ref: el=>_ctx.refMap.set('left',el)
    }, [
      _renderSlot(_ctx.$slots, "left", {}, undefined, true)
    ], 4),
    _createElementVNode("div", {
      class: "resize",
      ref: el=>_ctx.refMap.set('resize',el)
    }, " ⋮ ", 512),
    _createElementVNode("div", {
      class: "right",
      style: _normalizeStyle({width: 'calc(100% - '+(_ctx.otherParams.lwidth)+' - 5px)',height:'100%'}),
      ref: el=>_ctx.refMap.set('right',el)
    }, [
      _renderSlot(_ctx.$slots, "right", {}, undefined, true)
    ], 4)
  ], 512))
}