//如果是微服务模式，必须和config.applications对应项一致。因为在jf-consumer里面转发请求的时候要用到。
//本质上是对应服务的context-path
enum modelTypes {
    system = "/system",
    orderProvider = "/orderProvider"
}
const ip_local='192.168.120.53';//本机
const ip_test='sdmsdev.suxiangkj.com';//测试  43.129.193.190:7789
const ip_product='sdms.shineraycar.com';//生产 不要加http，因为下方要加

// let _type='local';
let _type='test';
// let _type='product';

const title='海外整车订单管理系统';

let _isCloud:boolean=false;//是否是微服务模式
const cloudParams={//微服务参数
    // applications:['financeProvider','toolsProvider','demoProvider','orderProvider'],//微服务中的所有服务模块
    applications:['orderProvider'],//微服务中的所有服务模块
    aliCloudPre:'projectName',//阿里云存储中的文件夹名称
    contextPath:'/projectName',//网关中配置的“访问上下文”
    localPort:9093//本地开发的时候，网关请求端口
}
const bootParams={//不是微服务参数
    applications:['orderProvider'],//后台服务的application.name
    aliCloudPre:'orderProvider',//阿里云存储中的文件夹名称
    contextPath:'/orderProvider',//访问上下文
    localPort:7789//本地开发的时候，请求端口
}


const config={
    type:_type,
    isCloud:_isCloud,//是否微服务模式
    flag1:false,//是否开启部分功能
    //系统模块集合，实际上是所有模块的context-path集合。两个用处：
    //1-建立websocket连接的时候。2-如果是微服务，在消费者（jf-consumer）访问具体业务模块的时候用到
    //如果不是微服务模式，可以把applications、aliCloudPre、contextPath配置为一样的名称
    applications:_isCloud?cloudParams.applications:bootParams.applications,
    //附件上传到阿里云的文件夹名称
    aliCloudPre:_isCloud?cloudParams.aliCloudPre:bootParams.aliCloudPre,
    //系统访问上下文，如果不是微服务模式，需要和后台项目的server.servlet.context-path配置保持一致;
    //如果是微服务模式，需要和网关配置的路由结合【spring.cloud.gateway.routes[0].predicates[0]=Path=/projectName/**】
    contextPath:_isCloud?cloudParams.contextPath:bootParams.contextPath,
    title:title,
    baseURL:_type=='local'?('http://'+ip_local+':'+(_isCloud?cloudParams.localPort:bootParams.localPort))
        :(
            _type=='test'?
                ('https://'+ip_test)//测试
                :
                ('https://'+ip_product)//生产
        ),
    modelTypes:modelTypes,
    //在刷新或者访问的时候，不需要作验证，直接放行的路由，没有的话，设置为空数组[]
    clientPathArr:['/effect'],
    //为app实例绑定全局属性（main和dialog处创建出实例之后会调用）
    bindAppProps:(app:any,props:any)=>{
        props.forEach((item:any)=>{
            app.config.globalProperties[item.key] = item.value;
        });
    }
}
export default config;