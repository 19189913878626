export default class Const {
    public static topHeight=46//顶部的高度
    public static tabHeight=34;//tab的高度
    public static listEngineBtnHeight=39;//列表引擎操作按钮高度
    public static listEngineCollapseHeight=31;//列表引擎查询条件折叠框高度

    public static tbTitleHeight=25;//表格标题的高度
    public static tbToolBarHeight=35;//表格工具条的高度
    public static tbPagerHeight=32;//分页工具栏高度

    public static jfAccessToken='Jf-Access-Token';
    public static jmReportAccessToken='JmReport-Access-Token';
    public static code='code'; //放到header中的，前端取的时候必须是小写，否则取不到，放入的时候可以不是小写
    public static downloadcode='downloadcode';


    public static _0000='0000';//成功
    public static _0001='0001';//token验证失败
    public static _0002='0002';//没有传入token
    public static _0009='0009';//token过期
    public static _0010='0010';//非法访问
    public static _9999='9999';//返回结果不验证，直接成功

    //--------------------------------------整车订单
    public static pi_template_russia="20231208110544000001";//俄罗斯PI模板
    public static status_0=0;//待审核 (客户提交过来或者后台新建，这个时候俗称意向订单)
    public static status_1=1;//意向订单审核不通过 （订单废了）
    public static status_2=2;//意向订单审核通过 （可以拟定pi了）

    public static status_3=3;//待业务审核（pi提交）
    public static status_4=4;//业务审核不通过（订单废了）
    public static status_5=5;//业务驳回（pi又可以提交了）
    public static status_6=6;//业务审核通过（待财务审核）

    public static status_7=7;//财务审核不通过（订单废了）
    public static status_8=8;//财务驳回（pi又可以提交了）
    public static status_9=9;//财务审核通过（待财务总监审核）

    public static status_10=10;//财务总监审核不通过（订单废了）
    public static status_11=11;//财务总监驳回（pi又可以提交了）
    public static status_12=12;//财务总监审核通过 （可以发布了，发布了意向订单才可以转正了，至于是否发布由另外的字段来存储）

    public static status_13=13;//正式订单

    public static status_14=14;//财务总监审核通过 （可以发布了，发布了意向订单才可以转正了，至于是否发布由另外的字段来存储）
    public static status_15=15;//正式订单

    public static xyz_status_0=0;//新增保存
    public static xyz_status_1=1;//提交给单证经理审批
    public static xyz_status_2=2;//单证经理驳回
    public static xyz_status_3=3;//单证经理提交给单证分管领导审批
    public static xyz_status_4=4;//单证分管领导驳回
    public static xyz_status_5=5;//单证分管领导提交给财务总监审批
    public static xyz_status_6=6;//财务总监驳回
    public static xyz_status_7=7;//财务总监提交给事业部总经理审批
    public static xyz_status_8=8;//事业部总经理驳回
    public static xyz_status_9=9;//事业部总经理同意通过，可以进行交单了
    public static xyz_status_10=10;//交单完成，可以进行银行入账了
    public static xyz_status_11=11;//银行入账完成，可以结算了
    //--------------------------------------下拉查询级别类型
    public static current=0;//本级
    public static cascade=1;//本级及下级
    public static all=2;//显示所有

    public static zh='zh';//中文
    public static en='en';//English
}