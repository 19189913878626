import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5d55a36a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialog" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-between","color":"var(--el-text-color-primary)"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createBlock(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      class: _normalizeClass([_ctx.customClass]),
      top: "10vh",
      width: _ctx.options.dWidth,
      fullscreen: _ctx.options.fullscreen,
      "close-on-click-modal": _ctx.options.closeModel,
      "destroy-on-close": "",
      onOpened: _ctx.opened,
      onOpen: _ctx.open,
      onClose: _ctx.close,
      onClosed: _ctx.closed,
      "element-loading-text": _ctx.$t('loadMsg'),
      "append-to-body": "",
      draggable: "",
      "show-close": false
    }, {
      header: _withCtx(({ close, titleId, titleClass }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.options.title), 1),
          _createElementVNode("div", null, [
            (_ctx.options.fullscreen && _ctx.options.showMaxRecover)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "iconfont icon-changtai",
                  style: {"margin-right":"10px","font-size":"10px","margin-top":"5px","cursor":"pointer"},
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.recoveryHandler && _ctx.recoveryHandler(...args)))
                }))
              : (_ctx.options.showMaxRecover)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 1,
                    class: "iconfont icon-zuidahua",
                    style: {"margin-right":"10px","font-size":"12px","margin-top":"5px","cursor":"pointer"},
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.maxHandler && _ctx.maxHandler(...args)))
                  }))
                : _createCommentVNode("", true),
            _createElementVNode("i", {
              class: "iconfont icon-guanbi",
              style: {"font-size":"12px","margin-top":"5px","cursor":"pointer"},
              onClick: close
            }, null, 8, _hoisted_3)
          ])
        ])
      ]),
      footer: _withCtx(() => [
        (_ctx.options.showFooterBtn)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _renderSlot(_ctx.$slots, "footerBtn", {}, () => [
                _createVNode(_component_el_button, {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.bottomHandler('cancelHandler')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('close')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.bottomHandler('sureHandler')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('confirm')), 1)
                  ]),
                  _: 1
                })
              ], true)
            ]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          ref: el=>_ctx.refMap.set('content',el),
          style: _normalizeStyle({height:_ctx.options.contentHeight,overflow: _ctx.options.overflow,'animation-delay':_ctx.options.animationDelay,'animation-duration': _ctx.options.animationDuration}),
          class: _normalizeClass(_ctx.dialogContentClass)
        }, [
          _renderSlot(_ctx.$slots, "content", {}, () => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.engine), {
              ref: "engineRef",
              engineParams: _ctx.engineParams
            }, null, 8, ["engineParams"]))
          ], true)
        ], 6)
      ]),
      _: 3
    }, 8, ["modelValue", "class", "width", "fullscreen", "close-on-click-modal", "onOpened", "onOpen", "onClose", "onClosed", "element-loading-text"])), [
      [
        _directive_loading,
        _ctx.fullscreenLoading,
        void 0,
        {
          fullscreen: true,
          lock: true
        }
      ]
    ])
  ]))
}