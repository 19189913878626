import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-53eafe5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_Unit = _resolveComponent("Unit")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_select_v2 = _resolveComponent("el-select-v2")!
  const _component_ComboGrid = _resolveComponent("ComboGrid")!
  const _component_ComboTree = _resolveComponent("ComboTree")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_rate = _resolveComponent("el-rate")!
  const _component_el_slider = _resolveComponent("el-slider")!

  return (_ctx.item.type=='text')
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.form[_ctx.item.javaName]), 1))
    : (_ctx.item.type=='input')
      ? (_openBlock(), _createBlock(_component_el_input, _mergeProps({
          key: 1,
          onInput: _cache[0] || (_cache[0] = e => _ctx.form[_ctx.item.javaName] = _ctx.valid(e)),
          modelValue: _ctx.form[_ctx.item.javaName],
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
          maxlength: _ctx.item.maxlength,
          placeholder: '请输入'+_ctx.item.label,
          disabled: _ctx.disabled || _ctx.item.disabled,
          clearable: _ctx.item.clearable,
          ref: el=>_ctx.refMap.set(_ctx.item.javaName,el)
        }, _ctx.item.attrs), null, 16, ["modelValue", "maxlength", "placeholder", "disabled", "clearable"]))
      : (_ctx.item.type=='unit')
        ? (_openBlock(), _createBlock(_component_Unit, _mergeProps({
            key: 2,
            modelValue: _ctx.form[_ctx.item.javaName],
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
            maxlength: _ctx.item.maxlength,
            placeholder: '请输入'+_ctx.item.label,
            disabled: _ctx.disabled || _ctx.item.disabled,
            clearable: _ctx.item.clearable,
            ref: el=>_ctx.refMap.set(_ctx.item.javaName,el)
          }, _ctx.item.attrs), null, 16, ["modelValue", "maxlength", "placeholder", "disabled", "clearable"]))
        : (_ctx.item.type=='select')
          ? (_openBlock(), _createBlock(_component_el_select, _mergeProps({
              key: 3,
              modelValue: _ctx.form[_ctx.item.javaName],
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
              placeholder: '请选择'+_ctx.item.label,
              ref: el=>_ctx.refMap.set(_ctx.item.javaName,el),
              disabled: _ctx.disabled || _ctx.item.disabled,
              clearable: _ctx.item.clearable,
              style: {"width":"100%"},
              onChange: _cache[4] || (_cache[4] = (val)=>{if(_ctx.item.event)_ctx.item.event(val,_ctx.item.javaName,_ctx.ownerInst)})
            }, _ctx.item.attrs), {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.data, (it) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: it.value,
                    label: it.label,
                    value: it.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 16, ["modelValue", "placeholder", "disabled", "clearable"]))
          : (_ctx.item.type=='select-v2')
            ? (_openBlock(), _createBlock(_component_el_select_v2, _mergeProps({
                key: 4,
                modelValue: _ctx.form[_ctx.item.javaName],
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
                placeholder: '请选择'+_ctx.item.label,
                ref: el=>_ctx.refMap.set(_ctx.item.javaName,el),
                disabled: _ctx.disabled || _ctx.item.disabled,
                clearable: _ctx.item.clearable,
                style: {"width":"100%"},
                onChange: _cache[6] || (_cache[6] = (val)=>{if(_ctx.item.event)_ctx.item.event(val,_ctx.item.javaName,_ctx.ownerInst)}),
                options: _ctx.item.data
              }, _ctx.item.attrs), null, 16, ["modelValue", "placeholder", "disabled", "clearable", "options"]))
            : (_ctx.item.type=='comboGrid')
              ? (_openBlock(), _createBlock(_component_ComboGrid, _mergeProps({
                  key: 5,
                  modelValue: _ctx.form[_ctx.item.javaName],
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
                  disabled: _ctx.disabled || _ctx.item.disabled,
                  params: {comboId:_ctx.item.javaName,formRef:_ctx.formRef,modelMethod:_ctx.item.modelMethod},
                  ref: el=>_ctx.refMap.set(_ctx.item.javaName,el)
                }, _ctx.item.attrs), null, 16, ["modelValue", "disabled", "params"]))
              : (_ctx.item.type=='comboTree')
                ? (_openBlock(), _createBlock(_component_ComboTree, _mergeProps({
                    key: 6,
                    modelValue: _ctx.form[_ctx.item.javaName],
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
                    disabled: _ctx.disabled || _ctx.item.disabled,
                    params: {comboId:_ctx.item.javaName,formRef:_ctx.formRef,modelMethod:_ctx.item.modelMethod},
                    ref: el=>_ctx.refMap.set(_ctx.item.javaName,el)
                  }, _ctx.item.attrs), null, 16, ["modelValue", "disabled", "params"]))
                : (_ctx.item.type=='cascader')
                  ? (_openBlock(), _createBlock(_component_el_cascader, _mergeProps({
                      key: 7,
                      modelValue: _ctx.form[_ctx.item.javaName],
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
                      options: _ctx.item.data,
                      placeholder: '请选择'+_ctx.item.label,
                      style: {"width":"100%"},
                      disabled: _ctx.disabled || _ctx.item.disabled,
                      clearable: _ctx.item.clearable,
                      ref: el=>_ctx.refMap.set(_ctx.item.javaName,el),
                      onChange: _cache[10] || (_cache[10] = (val)=>{if(_ctx.item.event)_ctx.item.event(val,_ctx.item.javaName,_ctx.ownerInst)})
                    }, _ctx.item.attrs), null, 16, ["modelValue", "options", "placeholder", "disabled", "clearable"]))
                  : (_ctx.item.type=='datePicker')
                    ? (_openBlock(), _createBlock(_component_el_date_picker, _mergeProps({
                        key: 8,
                        modelValue: _ctx.form[_ctx.item.javaName],
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
                        placeholder: '请选择'+_ctx.item.label,
                        disabled: _ctx.disabled || _ctx.item.disabled,
                        clearable: _ctx.item.clearable,
                        ref: el=>_ctx.refMap.set(_ctx.item.javaName,el)
                      }, _ctx.item.attrs), null, 16, ["modelValue", "placeholder", "disabled", "clearable"]))
                    : (_ctx.item.type=='dateRangePicker')
                      ? (_openBlock(), _createBlock(_component_el_date_picker, _mergeProps({
                          key: 9,
                          modelValue: _ctx.form[_ctx.item.javaName],
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
                          placeholder: '请选择'+_ctx.item.label,
                          type: "daterange",
                          shortcuts: _ctx.utils.Tools.defDateRange(),
                          disabled: _ctx.disabled || _ctx.item.disabled,
                          clearable: _ctx.item.clearable,
                          ref: el=>_ctx.refMap.set(_ctx.item.javaName,el)
                        }, _ctx.item.attrs), null, 16, ["modelValue", "placeholder", "shortcuts", "disabled", "clearable"]))
                      : (_ctx.item.type=='switch')
                        ? (_openBlock(), _createBlock(_component_el_switch, _mergeProps({
                            key: 10,
                            modelValue: _ctx.form[_ctx.item.javaName],
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
                            "active-value": "true",
                            disabled: _ctx.disabled || _ctx.item.disabled,
                            ref: el=>_ctx.refMap.set(_ctx.item.javaName,el)
                          }, _ctx.item.attrs), null, 16, ["modelValue", "disabled"]))
                        : (_ctx.item.type=='rate')
                          ? (_openBlock(), _createBlock(_component_el_rate, _mergeProps({
                              key: 11,
                              modelValue: _ctx.form[_ctx.item.javaName],
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
                              "allow-half": "",
                              disabled: _ctx.disabled || _ctx.item.disabled
                            }, _ctx.item.attrs), null, 16, ["modelValue", "disabled"]))
                          : (_ctx.item.type=='slider')
                            ? (_openBlock(), _createBlock(_component_el_slider, _mergeProps({
                                key: 12,
                                modelValue: _ctx.form[_ctx.item.javaName],
                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form[_ctx.item.javaName]) = $event)),
                                ":disabled": _ctx.disabled || _ctx.item.disabled
                              }, _ctx.item.attrs), null, 16, ["modelValue", ":disabled"]))
                            : _createCommentVNode("", true)
}